<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('offer',2)"
        :items="offers2"
        :items-context="offersContext"
        :available-filters="$store.getters['offer2/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-view-windows="$can('manage', 'offers')"
        :is-view-windows-ready="displayedOffer!=null"

        @addItem="offerModalShow=true"
        @showDetails="showDetails"
        @resetDisplayedViewWindow="displayedOffer=null"
    >
      <template #listView_cell__status="{item}">
        <b-avatar
            :id="`offer-row-${item.id}`"
            size="32"
            :variant="`light-${getOfferIcon(item._status).variant}`"
        >
          <icon
              :icon="getOfferIcon(item._status).icon"
          />
        </b-avatar>
        <b-tooltip
            :target="`offer-row-${item.id}`"
            placement="left"
        >
          <p class="mb-0">
            {{ $t(item._status) }}
          </p>
        </b-tooltip>
      </template>
      <template #listView_cell_actions="{item}">
        <!--          Download-->
        <button-download
            v-b-tooltip.hover.left="$t('Download')"
            @click.native.stop="downloadOfferQuote(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Validate-->
        <button-validate
            v-if="item._isValidated == false && item.isDeclined != true"
            @click.native.stop="validateOfferLocal(item)"
            v-b-tooltip.hover.left="$t('ValidatedOffer')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Decline-->
        <button-close
            v-if="item._isValidated == false && item.isDeclined != true && item.isArchived == false"
            @click.native.stop="declinedOfferLocal(item, true)"
            v-b-tooltip.hover.left="$t('DeclinedOffer')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--        Transform to outgoing invoice-->
        <button-to-invoice
            @click.native.stop="transformProductsToCustomerInvoiceLocal(item)"
            v-b-tooltip.hover.left="capitalize($t('transformOfferToOutgoingInvoice'))"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="isTransformDisabled(item)"
            size="sm"
        />

        <!--          Edit -->
        <button-edit
            @click.native.stop="editOfferLocal(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="item.signedDocumentDate != null"
            size="sm"
        />

        <!--          Archivate -->
        <archivate
            v-if="(item._isValidated == true || item.isDeclined == true) && item.isArchived == false"
            @click.native.stop="archiveOfferLocal(item, true)"
            v-b-tooltip.hover.left="$t('ArchiveOffer')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          UnArchivate -->
        <unarchivate
            v-if="(item._isValidated == true || item.isDeclined == true) && item.isArchived == true"
            @click.native.stop="archiveOfferLocal(item, false)"
            v-b-tooltip.hover.left="$t('UnArchiveOffer')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Duplicate-->
        <button-duplicate
            v-if="$can('manage', 'offers')"
            v-b-tooltip.hover.left="capitalize($t('duplicate'))"
            @click.native.stop="duplicateOffer(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deleteOfferAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>

      <template #leftViewWindow="{item}">
        <offer-card
            :offer="item"
            :is-viewable="true"
            :is-selected="item.id==displayedOffer.id"
            :is-edit-allowed="false"
            :is-trash-allowed="false"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedOffer.billNumber }}
      </template>
      <template #viewWindowButtons>
        <!--          Download -->
        <button-download
            @click.native.stop="downloadOffer(displayedOffer)"
            v-b-tooltip.hover.left="$t('Download')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Validate -->
        <button-validate
            v-if="displayedOffer.billNumber == null"
            @click.native.stop="validateOfferLocal(displayedOffer)"
            v-b-tooltip.hover.left="$t('validate')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Decline-->
        <button-close
            v-if="displayedOffer._isValidated == false && displayedOffer.isDeclined != true && displayedOffer.isArchived == false"
            @click.native.stop="declinedOfferLocal(displayedOffer, true)"
            v-b-tooltip.hover.left="$t('DeclinedOffer')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--        Transform to outgoing invoice-->
        <button-to-invoice
            @click.native.stop="transformProductsToCustomerInvoiceLocal(displayedOffer)"
            v-b-tooltip.hover.left="capitalize($t('transformOfferToOutgoingInvoice'))"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="isTransformDisabled(displayedOffer)"
            size="sm"
        />

        <!--          Edit -->
        <button-edit
            @click.native.stop="editOfferLocal(displayedOffer)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="displayedOffer.signedDocumentDate != null"
            size="sm"
        />

        <!--          Archivate -->
        <archivate
            v-if="(displayedOffer._isValidated == true || displayedOffer.isDeclined == true) && displayedOffer.isArchived == false"
            @click.native.stop="archiveOfferLocal(displayedOffer, true)"
            v-b-tooltip.hover.left="$t('ArchiveOffer')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          UnArchivate -->
        <unarchivate
            v-if="(displayedOffer._isValidated == true || displayedOffer.isDeclined == true) && displayedOffer.isArchived == true"
            @click.native.stop="archiveOfferLocal(displayedOffer, false)"
            v-b-tooltip.hover.left="$t('UnArchiveOffer')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>
      <template #windowView>
        <offer-view
            :offer="displayedOffer"
            :selectedProducts.sync="selectedProducts"
        />
      </template>

    </base-template>

    <!-- modal supplier invoice -->
    <modal-offer
        :offer.sync="currentOffer"
        :title="('id' in currentOffer)?$t('EditOffer'):$t('NewOffer')"
        :is-open.sync="offerModalShow"
        @submitValidatedOffer="submitValidatedOfferLocal"
    />

    <!-- modal offer validation-->
    <modal-offer-validation
        :offer.sync="currentOffer"
        :isOpen.sync="isOfferValidationModalOpen"
        @submitValidatedOfferValidationForm="submitValidatedOfferValidationForm"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { clone, getRoute }      from '../../utils/utils'
import { capitalize }           from '../../utils/filter'
import { useOffers } from '../../views/sales/offers2/useOffers'

import useAPI from '../../utils/useAPI'
import store from '../../store'
import moment from 'moment'

import BaseTemplate from '../base3/Base.vue'
import ButtonRemove from '../button/Remove.vue'
import ButtonEdit from '../button/Edit.vue'
import ButtonDownload from '../button/Download.vue'
import ButtonDuplicate from '../button/Duplicate.vue'
import ButtonToInvoice from '../button/ToInvoice2.vue'
import ButtonClose from '../button/Close.vue'
import ButtonValidate from '../button/Validate.vue'
import OfferCard from '../card/Offer2.vue'
import OfferView from './OfferView.vue'
import ModalOffer from '../prompt/Offer.vue'
import ModalOfferValidation from '../prompt/OfferValidation.vue'
import Archivate from '../button/Archivate.vue'
import Unarchivate from '../button/Unarchivate.vue'

export default {
  components: {
    Unarchivate,
    Archivate,
    ModalOfferValidation,
    ModalOffer,
    OfferView,
    OfferCard,
    ButtonValidate,
    ButtonDownload,
    ButtonDuplicate,
    ButtonToInvoice,
    ButtonClose,
    ButtonEdit,
    ButtonRemove,
    BaseTemplate
  },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate = ref()
    const module = ref('offers')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedOffer = ref(null)
    const currentOffer = ref(null)
    const offerModalShow = ref(false)
    const isOfferValidationModalOpen = ref(false)
    const selectedProducts = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { offers2, offersContext, autocompleteWorkFlos } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      if (getRoute().name == 'WorkFlo view') {
        emit('updatePayload', val)
      } else {
        fetchOffers2(val)
            .then(() => {
              if (!isAutocompleteLoaded.value) {
                loadInit()
              }
            })
      }

    }, { deep: true })

    watch(offerModalShow, val => {
      if (val == false) {
        resetOffer()
      }
    })

    watch(autocompleteWorkFlos, val => {
      resetOffer()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchOffers2,
      fetchAutocompleteArticles,
      fetchAutocompleteContacts,
      fetchAutocompletePaymentMethods,
      fetchAutocompletePaymentTerms,
      fetchAutocompleteVats,
      fetchAutocompleteWorkFlos,
      fetchAutocompleteWorkflows,
    } = useAPI()

    const {
      submitValidatedOffer,
      removeOffer,
      downloadOfferQuote,
      transformProductsToOutgoingInvoice,
      recognizeOffer
    } = useOffers()

    const loadInit = () => {
      fetchAutocompleteArticles()
      fetchAutocompleteContacts()
      fetchAutocompletePaymentMethods()
      fetchAutocompletePaymentTerms()
      fetchAutocompleteVats()
      fetchAutocompleteWorkFlos()
      fetchAutocompleteWorkflows()

      isAutocompleteLoaded.value = true
    }

    const showDetails = (offer) => {
      if (offer != null) {
        store.dispatch('offer2/getOffer', offer.id)
            .then(response => {
              displayedOffer.value = response
            })

      } else {
        resetOffer()
      }
    }

    const resetOffer = () => {
      currentOffer.value = JSON.parse(JSON.stringify(store.getters['offer2/getEmptyOffer']))

      if (getRoute().name == 'WorkFlo view') {
        if (!autocompleteWorkFlos.value.length) {
          currentOffer.value.workFlo = {
            id: parseInt(getRoute().params.workFloId)
          }
        } else {
          currentOffer.value.workFlo = store.getters['workFlo/getAutocompleteWorkFlos'].find(w => w.id == parseInt(getRoute().params.workFloId))
        }
      }
    }

    const editOfferLocal = (offer) => {
      store.dispatch('offer2/getOffer', offer.id)
          .then(response => {
            currentOffer.value = response
            displayedOffer.value = response
            offerModalShow.value = true
          })
    }

    const removeOfferLocal = (offer) => {
      removeOffer(offer)
          .then(response => {

          })
    }

    const duplicateOffer = (offer) => {
      store.dispatch('offer2/getOffer', offer.id)
          .then(response => {
            currentOffer.value = response

            let expirationDate = new Date()
            expirationDate.setDate(expirationDate.getDate() + 30)
            currentOffer.value.billingDate = (new Date()).toISOString().slice(0, 10)
            currentOffer.value.expirationDate = expirationDate.toISOString().slice(0, 10)
            currentOffer.value.signedDocumentNumber = null
            currentOffer.value.signedDocumentDate = null
            currentOffer.value.signedDocument = null
            currentOffer.value.version = 1

            let products = []
            response.products.forEach(p=> {
              p.childs=null
              products.push(p)
            })
            currentOffer.value.products = products

            delete currentOffer.value.id
            delete currentOffer.value.logs
            delete currentOffer.value.orders
            delete currentOffer.value.receipt

            offerModalShow.value = true
          })
    }

    const validateOfferLocal = (offer) => {
      store.dispatch('offer2/getOffer', offer.id)
          .then(response => {
            // console.log(response)
            currentOffer.value = response
            // populateWorkflow(currentOffer.value)
            currentOffer.value.signedDocumentDate = (new Date()).toISOString().slice(0, 10)
            isOfferValidationModalOpen.value = true
          })
    }

    const declinedOfferLocal = (offer, isDeclined) => {
      store.dispatch('offer2/getOffer', offer.id)
          .then(response => {
            currentOffer.value = response
            // populateWorkflow(currentOffer.value)
            currentOffer.value.isDeclined = isDeclined

            submitValidatedOffer(currentOffer.value)
                .then(response => {
                  // updateWorkflow(response.data)
                  resetOffer()
                })
          })
    }

    const submitValidatedOfferValidationForm = () => {
      submitValidatedOffer(currentOffer.value)
          .then(response => {
            // console.log(response)
            if (response.data.workFlo != null) {
              store.dispatch('workFlo/getWorkFlo', response.data.workFlo.id)
              // let workFlo = store.getters['workFlo/getWorkFlo'](response.data.workFlo.id)
              //
              // console.log(JSON.parse(JSON.stringify(workFlo)))
              // workFlo.status = {
              //   id: 6,
              //   name: 'En cours de livraison'
              // }
              // console.log(workFlo)
              // store.commit('workFlo/UPDATE_WORKFLO', workFlo)
            }

            resetOffer()
            isOfferValidationModalOpen.value = false
          })
    }

    const submitValidatedOfferLocal = () => {
      // console.log(currentOffer.value)
      submitValidatedOffer(currentOffer.value)
          .then(response => {
            resetOffer()
            offerModalShow.value = false
          })
    }

    const getOfferIcon = (status) => {
      if (status == 'Canceled') return { variant: 'danger', icon: 'times' }
      else if (status == 'Fully invoiced') return { variant: 'success', icon: 'check' }
      else if (status == 'Partially invoiced') return { variant: 'warning', icon: 'spinner' }
      else if (status == 'Declined') return { variant: 'danger', icon: 'times' }
      else if (status == 'Waiting for invoicing') return { variant: 'primary', icon: 'signature' }
      else if (status == 'Waiting for signature') return { variant: 'secondary', icon: 'hourglass' }

    }

    const isTransformDisabled = (offer) => {
      return !offer._isValidated || offer._status == 'Fully invoiced'
    }

    const transformProductsToCustomerInvoiceLocal = (offer = null) => {
      if (offer == null) {
        transformProductsToCustomerInvoiceLocalTmp(displayedOffer.value)
      } else {
        store.dispatch('offer2/getOffer', offer.id)
            .then(response => {
              transformProductsToCustomerInvoiceLocalTmp(response)
            })
      }

    }

    const transformProductsToCustomerInvoiceLocalTmp = (offer) => {
      if (selectedProducts.value.length == 0) {
        selectedProducts.value = offer.products.filter(p => p.outgoingInvoice == null)

      }
      let selectedProductsIds = selectedProducts.value.map(p => p.id)
      transformProductsToOutgoingInvoice(offer, selectedProducts.value)
          .then(response => {
            selectedProducts.value = []

            offer.products.forEach(p => {
              if (p.outgoingInvoice == null) {
                selectedProductsIds.forEach(selectedId => {
                  if (selectedId == p.id) {
                    p.outgoingInvoice = response.data
                  }
                })
              }
            })

            baseTemplate.value.closeViewWindow()
          })
    }

    const archiveOfferLocal = (offer, value) => {
      console.log(value)
      store.dispatch('offer2/getOffer', offer.id)
          .then(response => {
            currentOffer.value = response
            // populateWorkflow(currentOffer.value)
            currentOffer.value.isArchived = value

            submitValidatedOffer(currentOffer.value)
                .then(response => {
                  // updateWorkflow(response.data)
                  resetOffer()
                })
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetOffer()
    if (getRoute().name != 'WorkFlo view') {
      store.dispatch('moduleView/getModuleViews')
          .then((response) => {
            let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

            payload.value = {
              filters: currentView.filters,
              kanbanSortBy: currentView.kanbanSortBy,
              kanbanColumnAmount: currentView.kanbanColumnAmount,
              listGlobalAmount: currentView.listGlobalAmount,
              page: 1,
              sorts: currentView.sorts,
            }

          })
    } else {
      let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

      payload.value = {
        filters: currentView.filters,
        kanbanSortBy: currentView.kanbanSortBy,
        kanbanColumnAmount: currentView.kanbanColumnAmount,
        listGlobalAmount: currentView.listGlobalAmount,
        page: 1,
        sorts: currentView.sorts,
      }
    }

    return {
      // Components
      capitalize,

      // Data
      baseTemplate,
      module,
      payload,
      isReady,

      displayedOffer,
      currentOffer,
      offerModalShow,
      isOfferValidationModalOpen,
      selectedProducts,

      // Computed
      offers2,
      offersContext,

      // Methods
      showDetails,
      editOfferLocal,
      removeOfferLocal,
      downloadOfferQuote,
      duplicateOffer,
      validateOfferLocal,
      declinedOfferLocal,
      submitValidatedOfferValidationForm,
      submitValidatedOfferLocal,
      getOfferIcon,
      transformProductsToCustomerInvoiceLocal,
      isTransformDisabled,
      archiveOfferLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteOfferAlert (offer) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theOffer') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeOfferLocal(offer)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>