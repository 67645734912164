<template>
  <div v-if="'billingAddress' in offer" class="invoice-preview-wrapper invoice-preview">
    <b-tabs>

      <b-tab :title="capitalize($tc('quote', 1))">
        <b-card
            no-body
            class="invoice-preview-card position-relative"
            v-if="offer"
        >
          <div
              class="position-absolute h-100 w-100 overflow-hidden"
              :style="'z-index: '+(isDisabled?2:0)"
          >
            <div class="d-flex h-100">
              <div class="m-auto">
                <p style="font-size:45px; transform:rotate(310deg);">
                  <span v-if="offer.isDeclined"
                        class="text-danger">{{ $t('DECLINED') }}
                  </span>
                  <span v-else-if="offer.isCanceled"
                        class="text-danger">{{ $t('CANCELED') }}
                  </span>
                  <span v-else-if="offer.isArchived"
                        class="text-warning">{{ $t('ARCHIVED') }}
                  </span>
                  <span v-else-if="!offer._isValidated"
                        style="color:#f1f1f1;">{{ $t('AWAITINGSIGNATURE') }}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div style="z-index:1;"
               :class="{'bg-disabled': isDisabled}">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div></div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 v-if="offer._isValidated"
                      class="invoice-title mb-0">{{ capitalize($tc('order')) }}
                    <span v-if="offer.orderNumber"
                          class="invoice-number">{{ offer.orderNumber }}
                    </span>
                  </h4>

                  <h4
                      class="invoice-title"
                      :class="offer._isValidated?'font-italic':''"
                  >{{ capitalize($tc('offer')) }}
                    <span v-if="offer.billNumber"
                          class="invoice-number">{{ offer.billNumber }}
                    </span>
                  </h4>

                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t('OfferBillingDate:') }}
                    </p>
                    <p class="invoice-date">
                      {{ offer.billingDate|moment('L') }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t('DueDate:') }}
                    </p>
                    <p class="invoice-date">
                      {{ offer.expirationDate|moment('L') }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
                class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                    cols="12"
                    xl="6"
                    class="p-0"
                >
                  <h6 class="mb-1">
                    <b>{{ capitalize($t('billingAddress')) }} :</b>
                  </h6>
                  <p v-if="'_customer' in offer" class="card-text mb-25">
                    {{ offer._customer._display }}
                  </p>
                  <p  class="card-text mb-25">
                    {{ offer.billingAddress._display }}
                  </p>
                  <p class="card-text mb-0">
                    {{ getCountry(offer.billingAddress.country) }}
                  </p>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                    xl="6"
                    cols="12"
                    class="p-0"
                >
                  <h6 class="mb-1">
                    <b>{{ capitalize($t('deliveryAddress')) }} :</b>
                  </h6>
                  <p class="card-text mb-25">
                    {{ contact._display }}
                  </p>
                  <p class="card-text mb-25">
                    {{ offer.deliveryAddress._display }}
                  </p>
                  <p class="card-text mb-0">
                    {{ getCountry(offer.deliveryAddress.country) }}
                  </p>

                </b-col>
              </b-row>

              <b-row class="mt-3 mb-1">
                <b-col cols="12">
                  <b>{{ offer.title }}</b>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite
                responsive
                :items="offer.products"
                :fields="tableColumns"
                :disabled="true"
                striped
            >
              <template #head(toInvoice)="data">
                <div class="d-flex justify-content-between">
                  <b-form-checkbox
                      v-b-tooltip.hover.right="$t('toInvoice')"
                      v-model="selectAll"
                      :disabled="isSelectAllDisabled"
                  />
                  <div v-if="selectedProductsLocal.length">
                    <span class="text-uppercase">{{ $t('quantity') }}</span>
                  </div>
                </div>
              </template>

              <template #cell(toInvoice)="data">
                <div class="d-flex justify-content-between">
                  <b-form-checkbox
                      v-model="selectedProductsLocal"
                      :value="data.item"
                      :disabled="data.item._remaining <= 0"
                  />
                  <div v-if="selectedProductsLocal.some(p=>p.id == data.item.id)">
                    <field-input
                        :name="'selectedQuantity'"
                        :listIndex="data.item.id"
                        :displayLabel="false"
                        :rules="'required|max_value:'+data.item._remaining"
                        :model.sync="data.item._selectedQuantity"
                        :key="componentKey"
                    />
                  </div>
                </div>

              </template>

              <template #cell(article)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.article.name }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  <div v-html="data.item.article.description"></div>
                </b-card-text>
              </template>

              <template #cell(quantity)="data">
                <b-card-text class="">
                  {{ data.item.quantity }}
                </b-card-text>
              </template>

              <template #cell(unitPrice)="data">
                <b-card-text class="">
                  {{ currency(data.item.unitPrice) }}
                </b-card-text>
              </template>

              <template #cell(discount)="data">
                <b-card-text
                    v-if="data.item.discount != null"
                    class=""
                >
                  <span v-if="data.item.isDiscountPercent">{{ percent(data.item.discount) }}</span>
                  <span v-else>{{ currency(data.item.discount) }}</span>
                </b-card-text>
                <b-card-text
                    v-else
                    class=""
                >
                  -
                </b-card-text>
              </template>

              <template #cell(preTaxAmount)="data">
                <b-card-text class="">
                  {{ currency(data.item.preTaxAmount) }}
                </b-card-text>
              </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3"
                    order="2"
                    order-md="1"
                >

                </b-col>

                <!-- Col: Total -->
                <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                >
                  <div class="invoice-total-wrapper">

                    <!--            Discount-->
                    <div
                        v-if="offer.discount"
                        class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        {{ $t('Discount:') }}
                      </p>
                      <p class="invoice-total-amount">
                        <span v-if="offer.isDiscountPercent">{{ percent(offer.discount) }}</span>
                        <span v-else>{{ currency(offer.discount) }}</span>
                      </p>
                    </div>

                    <!--            Pre tax amount-->
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t('PreTaxAmount:') }}
                      </p>
                      <p class="invoice-total-amount">
                        {{ currency(offer.preTaxAmount) }}
                      </p>
                    </div>

                    <!--            Vat-->
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t('VAT:') }}
                      </p>
                      <p class="invoice-total-amount">
                        {{ currency(offer.vat) }}
                      </p>
                    </div>

                    <!--            Total amount-->
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t('TotalAmount:') }}
                      </p>
                      <p class="invoice-total-amount">
                        {{ currency(offer.totalAmount) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span>
                <b>{{ $t('PaymentMethod:') }}&nbsp;</b> &nbsp;
              </span>
              <span>{{ $t(offer.paymentMethod.name) }}</span>
            </b-card-body>
          </div>

        </b-card>
      </b-tab>

      <b-tab :title="capitalize($tc('document', 2))"
             class="mb-2">
        <b-row>
          <b-col md="4">
            <h1>{{ capitalize($tc('quote', 2)) }}</h1>

            <card-document
                v-for="quote in documentQuotes"
                :document="quote"
                :isDeletable="false"
                @download="downloadByDocument(quote)"
                @click="downloadByDocument(quote)"
            />

          </b-col>

          <b-col md="4">
            <h1>{{ capitalize($t('signedDocument')) }}</h1>

            <card-document
                v-if="offer.signedDocument"
                :document="offer.signedDocument"
                :isDeletable="false"
                @download="downloadByDocument(offer.signedDocument)"
                @click="downloadByDocument(offer.signedDocument)"
            />
            <small v-else>
              <i>{{ capitalize($t('noDocument')) }}</i>
            </small>
          </b-col>

          <b-col md="4">
            <h1>{{ capitalize($t('linkedInvoices')) }}</h1>

            <card-document
                v-for="outgoingInvoice in offer._linkedOutgoingInvoices"
                :document="{extension: 'pdf', name: outgoingInvoice.billNumber==null?capitalize($t('draft')):outgoingInvoice.billNumber}"
                :isDeletable="false"
                :isLinkable="false"
                @click="downloadOutgoingInvoice(outgoingInvoice)"
                @download="downloadOutgoingInvoice(outgoingInvoice)"
            />
            <small v-if="'_linkedOutgoingInvoices' in offer && !offer._linkedOutgoingInvoices.length">
              <i>{{ capitalize($t('noDocument')) }}</i>
            </small>
          </b-col>

        </b-row>
      </b-tab>


    </b-tabs>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '../../utils/filter'
import { downloadByDocument } from '../../utils/utils'

import FieldInput from '../input/Input'
import i18n from '../../libs/i18n'
import useAPI from '../../utils/useAPI'
import CardDocument from '../card/Document.vue'
import { useOutgoingInvoices } from '../../views/sales/outgoingInvoices/useOutgoingInvoices'
import store from '../../store'

export default {
  components: {
    FieldInput,
    CardDocument,
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    },
    // offerId: {
    //   type: Number
    // },
    selectedProducts: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const selectedProductsLocal = ref(props.selectedProducts)
    const tableColumns = ref([])
    const selectAll = ref(false)
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { getCountry } = useAPI()

    const contact = computed(() => {
      if (props.offer.customerCompany != null) {
        // if ()
        return props.offer.customerCompany
      } else {
        return props.offer.customerIndividual
      }
    })

    const isSelectAllDisabled = computed(() => {
      return !props.offer.products.some(p => p._remaining != 0)
    })

    const selectableProducts = computed(() => {
      if (isDisabled == true) return []
      else return props.offer.products.filter(p => p.outgoingInvoice == null)
    })

    const isDisabled = computed(() => {
      return props.offer.isDeclined || props.offer.isArchived || props.offer.isCanceled
    })

    const isMounted = computed(() => {
      return ('billNumber' in props.offer)
    })

    const documentQuotes = computed(() => {
      // return props.offer.quotes.reverse()
      return props.offer.quotes
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectAll, (val) => {
      if (val == true) {
        selectedProductsLocal.value = selectableProducts.value

      } else if (
          selectedProductsLocal.value.length == selectableProducts.value.length &&
          selectableProducts.value.length != 0
      ) {
        selectedProductsLocal.value = []
      }
    })

    watch(selectedProductsLocal, (val) => {
      if (val.length != selectableProducts.value.length) selectAll.value = false
      else selectAll.value = true
console.log(val)
      resetSelectedQuantity()
      val.forEach(selectedProduct => {
        if (!('_selectedQuantity' in selectedProduct)) {
          selectedProduct._selectedQuantity = JSON.parse(JSON.stringify(selectedProduct._remaining))
        }
      })

      emit('update:selectedProducts', val)
      componentKey.value++
    })

    watch(isMounted, val => {
      if (val == true) {
        if ('_linkedIncomingInvoices' in props.offer && props.offer._linkedIncomingInvoices.length) {
          if (props.offer._isValidated == true && props.offer.isDisabled == false) {
            tableColumns.value.splice(3, 0, { key: '_remaining', label: i18n.t('remaining'), sortable: true })
          } else {
            tableColumns.value.splice(2, 0, { key: '_remaining', label: i18n.t('remaining'), sortable: true })
          }
        }
      }
    })

    watch(() => props.offer, val => {
      updateTableColumns()
    }, { deep: true })

    watch(selectedProductsLocal, val => {
      emit('update:selectedProducts', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadOutgoingInvoice } = useOutgoingInvoices()

    const resetSelectedQuantity = () => {
      selectableProducts.value.forEach(sp => {
        if (!selectedProductsLocal.value.some(p => p.id == sp.id)) {
          delete sp._selectedQuantity
        }
      })
    }

    const updateTableColumns = () => {
      resetTableColumns()

      if (props.offer._isValidated == true && isDisabled.value == false) {
        tableColumns.value.unshift({ key: 'toInvoice', sortable: false })
      } else {
        if (tableColumns.value.length == 7) {
          tableColumns.value.shift()
          tableColumns.value.shift()
        }
      }

      if (
        '_linkedIncomingInvoices' in props.offer &&
        props.offer._linkedOutgoingInvoices.length &&
          !tableColumns.value.some(c => c.key == '_remaining')
      ) {
        selectedProductsLocal.value = []
        if (props.offer._isValidated == true && isDisabled.value == false) {
          tableColumns.value.splice(3, 0, { key: '_remaining', label: i18n.t('remaining'), sortable: true })
        } else {
          tableColumns.value.splice(2, 0, { key: '_remaining', label: i18n.t('remaining'), sortable: true })
        }
      }
    }

    const resetTableColumns = () => {
      tableColumns.value = [
        { key: 'article', label: i18n.t('article'), sortable: true, thClass: 'w-100', tdClass: '' },
        { key: 'quantity', label: i18n.t('quantity'), sortable: false, thClass: 'text-nowrap', tdClass: 'text-nowrap' },
        {
          key: 'unitPrice',
          label: i18n.t('unitPrice'),
          sortable: false,
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        { key: 'discount', label: i18n.t('discount'), sortable: true, thClass: 'text-nowrap', tdClass: 'text-nowrap' },
        {
          key: 'preTaxAmount',
          label: i18n.t('preTaxAmount'),
          sortable: true,
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
      ]
    }

    // const emitSelectedProducts = () => {
    //
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    updateTableColumns()

    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data
      tableColumns,
      selectAll,
      selectedProductsLocal,
      // selectedProductsLocal,
      componentKey,

      // Computed
      getCountry,
      contact,
      isSelectAllDisabled,
      selectableProducts,
      isDisabled,
      documentQuotes,

      // Methods
      downloadByDocument,
      downloadOutgoingInvoice,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss"
       scoped>
@import "~@/assets/scss/base/pages/invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>